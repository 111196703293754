<!--
 * @Description:  财务综合管理 短信信息 smsMessages
 * @Author: houjinduo
 * @Date: 2024-05-9 15:48:47
 * @LastEditors: houjinduo
-->
<template>
  <div class='mainBody'>
    <!-- 上半部查询 -->
    <el-row class="topSelectTwoLine">
      <el-form :model="searchForm"
               :inline="true">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="停车场">
              <el-select class="search-input"
                         clearable
                         v-model="searchForm.parkName"
                         placeholder="全部">
                <el-option v-for="item in parkNameList"
                           :key="item.name"
                           :label="item.name"
                           :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌号">
              <el-input v-model="searchForm.plateNumber"
                        placeholder="请输入">

              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="发送时间">
              <el-date-picker v-model="time"
                              type="datetimerange"
                              align="right"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              unlink-panels
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="onSubmit">查 询</el-button>
          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>短信记录</span>
        <el-button type="info"
                   class="tableTitleButton"
                   @click="exportFile">导出</el-button>
      </el-row>
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :tableHeight="tableHeight"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
        </dt-table>
      </el-row>
    </el-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable
  },
  data () {
    //这里存放数据
    return {
      tableHeight: "540",
      tableList: { list: [] },
      listMap: { pageNum: 1, pageSize: 15 },
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableColumnList: [
        {
          prop: 'smsContent',
          label: '短信内容',
          config:{
            width: "1280",
          }
        },
        {
          prop: 'sendTime',
          label: '发送时间',
        },
      ],
      searchForm: {},
      parkNameList: [],  //停车场下拉框
      time: [],   //发送时间
      current: [],
      pageNum: 1,
      pageSize: 15,
      //日期快速选择
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      }
    };
  },
  //监听属性 类似于data概念
  computed: {
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryCurrentDay()
    this.queryTableList()//初始化页面
    this.queryParkNameList()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    onSubmit(){
      this.pageNum = 1;
      if (this.time) {
        this.searchForm.startTime = this.time[0]
        this.searchForm.endTime = this.time[1]
      } else {
        this.searchForm.startTime = this.current[0]
        this.searchForm.endTime = this.current[1]
      }
      this.queryTableList();
    },
    //重置
    resetForm () {
      this.searchForm = {}
      this.searchForm.startTime = this.current[0]
      this.searchForm.endTime = this.current[1]
      this.time = [this.current[0], this.current[1]]
      this.queryTableList()
    },
    // 导出
    exportFile () {
      this.$axios.post(this.$downloadBaseUrl + "txtMessageController/downloadInSms", this.searchForm, {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        },
      }).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "短信记录.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
      })
    },
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      const params = {
        tempId: "54",
        ...this.searchForm
      }
      this.$smsMessages.querySmsList(params).then(res => {
        console.log(res, '@!#!@#!$!$!');
        this.tableList.list = res.resultEntity.content
        this.paginationConfig.total = res.resultEntity.totalElements
      })
    },
    //获取停车场名称
    queryParkNameList () {
      let info = {
        columnName: ['park_id', 'park_name'],
        tableName: 'tb_park',
      }
      this.$queryDict.queryDictWithAuth(info).then((response) => {
        this.parkNameList = response.resultEntity
      })
    },
    // 获取当天时间
    queryCurrentDay () {
      let day = new Date()
      let a = day.getFullYear()
      let b = day.getMonth() + 1
      let c = day.getDate()
      if (JSON.stringify(b).length === 1) {
        b = "0" + b
      }
      if ((JSON.stringify(c).length === 1)) {
        c = "0" + c
      }
      this.current[0] = a + "-" + b + "-" + c + " 00:00:00"
      this.current[1] = a + "-" + b + "-" + c + " 23:59:59"
      if (this.time.length == 0) {
        this.time = this.current
        this.searchForm.startTime = this.current[0]
        this.searchForm.endTime = this.current[1]
      }
    },
  },
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
//@import url(); 引入公共css类
.mainBody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    min-height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    // min-height: calc(100% - #{$topSelectHeight} - 16px);
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      line-height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
</style>